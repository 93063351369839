import Web3 from 'web3'
import config from '../config'
import { MAX_UINT256 } from "../constants";
import BigNumber from "bignumber.js";
const rp = require('request-promise')
export function getWeb3(provider) {
  if (provider == "metamask")
  { 
    
    return new Web3(window.ethereum);
  }
  if(provider)
    window.ethereum.enable();
  return new Web3(window.ethereum);
}

export async function _checkApproval2(accountFrom, accountTo, amount){
  try {
    const web3 = await getWeb3()
    const erc20Contract = new web3.eth.Contract(config.connextErc20ABI, config.connextErc20Address)
    const allowance = await erc20Contract.methods.allowance(accountFrom,config.connextAppAddress).call({ from: accountFrom })
    console.log(allowance);
    let ethAllowance = web3.utils.fromWei(allowance, "ether")

    var amountToSend = MAX_UINT256

    if(parseFloat(ethAllowance) < parseFloat(amount)) {
      await erc20Contract.methods.approve(config.connextAppAddress, amountToSend).send({ from: accountFrom, gasPrice: web3.utils.toWei(await _getGasPrice(), 'gwei') })
      return true
    } else {
      return true
    }

  } catch(error) {
    console.log(error)
    if(error.message) {
      return false
    }
    return false
  }
}
export async function _getGasPrice(){
  // const url = 'https://gasprice.poa.network/'
  // const priceString = await rp(url)
  // const priceJSON = JSON.parse(priceString)
  // if(priceJSON) {
  //   return priceJSON.slow.toFixed(0)
  // }

  return "10"
}

export function tokenAmountShow(amount){
  return new BigNumber(amount)
  .dividedBy(new BigNumber(10).pow(18))
  .toFixed(5, BigNumber.ROUND_DOWN)
}

export function tokenAmountSend(amount){
  return new BigNumber(amount)
  .multipliedBy(new BigNumber(10).pow(18)) +''
}

export async function getRankData(account){
  const url = 'https://api.debank.com/social_ranking?id=' + account
  const rankDataString = await rp(url)
  const rankDataJSON = JSON.parse(rankDataString)
  if(rankDataJSON && rankDataJSON.error_code==0) {
    return rankDataJSON.data
  }

}

export async function popScanData(account){
  const url = 'https://frontend.poap.tech/actions/scan/' + account
  const rankDataString = await rp(url)
  const rankDataJSON = JSON.parse(rankDataString)
  if(rankDataJSON) {
    return rankDataJSON
  }

}

export async function galxeData(account){
  const url = 'https://graphigo.prd.galaxy.eco/query'
  const rankDataString = await rp({
    url:url,
    method:'POST',
    json:true,
    body:{
      "operationName": "ProfileCredentials",
      "variables": {
          "address": account,
          "first": 50,
          "after": "-1"
      },
      "query": "query ProfileCredentials($address: String!, $first: Int, $after: String) {\n  addressInfo(address: $address) {\n    eligibleCredentials(first: $first, after: $after) {\n      pageInfo {\n        endCursor\n        hasNextPage\n        __typename\n      }\n      list {\n        id\n        name\n        credType\n        credSource\n        curatorAddress\n        referenceLink\n        description\n        chain\n        lastUpdate\n        subgraph {\n          endpoint\n          query\n          expression\n          __typename\n        }\n        __typename\n      }\n      __typename\n    }\n    __typename\n  }\n}\n"
    },
    headers: {
      "content-Type": "application/json"
    }
  })
  const rankDataJSON = JSON.parse(rankDataString)
  if(rankDataJSON) {
    return rankDataJSON.data.addressInfo.eligibleCredentials.list
  }

}