import request from '@/utils/request'

export function saveProfile(data) {
  return request({
    url: '/talent/saveProfile',
    method: 'post',
    data
  })
}

export function getProfile(cnxtToken) {
  return request({
    url: '/talent/getProfile/'+cnxtToken,
    method: 'get'
  })
}

export function getProfileById(id) {
  return request({
    url: '/talent/getProfileById/'+id,
    method: 'get'
  })
}

export function queryProfiles(data) {
  return request({
    url: '/talent/queryProfiles',
    method: 'post',
    data
  })
}

export function getTalentRoles() {
  return request({
    url: '/talent/getTalentRoles',
    method: 'get'
  })
}

export function getTalentLocations() {
  return request({
    url: '/talent/getTalentLocations',
    method: 'get'
  })
}

export function getDataFromGalxe(cnxtToken) {
  return request({
    url: '/talent/getDataFromGalxe/'+cnxtToken,
    method: 'get'
  })
}

